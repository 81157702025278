import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "row q-col-gutter-sm" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "col-3" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "row items-center justify-end q-mt-sm" }

import date from 'quasar/src/utils/date.js';import QInput from 'quasar/src/components/input/QInput.js';import QSelect from 'quasar/src/components/select/QSelect.js';import QToggle from 'quasar/src/components/toggle/QToggle.js';;
import { computed, Ref, ref, watch } from "vue";

import SegmentSelect from "@/components/shared/segment/SegmentSelect.vue";
import { ProductLine } from "@/lib/productLine";

import { MessageTypeEnum, UseConversionReport } from "./useConversionReport";

type ComponentDefinition = {
  component:
    | typeof QToggle
    | typeof QInput
    | typeof QSelect
    | typeof SegmentSelect;
  segment?: string;
  staticList?: string[];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ConversionReport',
  props: {
    messageId: {},
    productLine: {},
    messageType: {}
  },
  setup(__props: any) {



const successRateProperty = ref(null);
const successRatePropertyOptions: Record<string, ComponentDefinition> = {
  // map name to type
  aria_activated: { component: QToggle },
  config_bundle: { component: QInput },
  config_bundle_activated: { component: QToggle },
  full_version: { component: SegmentSelect, segment: "fullappversion" },
  is_default_browser: { component: QToggle },
  live_scores_2_0_user: { component: QToggle },
  live_scores_enabled: { component: QToggle },
  version: { component: SegmentSelect, segment: "appversion" },
  vpn_enabled: { component: QToggle },
  wallet_activated: { component: QToggle },
  minipay_status: {
    component: SegmentSelect,
    staticList: [
      "entered",
      "created",
      "restored",
      "has_used",
      "disabled",
      "enabled",
      "signed_out",
    ],
  },
  wallpaper_type: {
    component: SegmentSelect,
    staticList: [
      "No wallpaper",
      "Default",
      "User-wallpaper",
      "ConfigBundle-wallpaper",
      "OMfA-wallpaper",
    ],
  },
};

const presetDateOptions = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Yesterday",
    value: "yesterday",
  },
  {
    label: "Last 7 Days",
    value: "7 day",
  },
  {
    label: "Last 14 Days",
    value: "14 day",
  },
  {
    label: "Last 30 Days",
    value: "30 day",
  },
  {
    label: "Last 90 Days",
    value: "90 day",
  },
  {
    label: "Year to Date",
    value: "this year to second",
  },
];

const lookerLinkDisabled = ref(true);
const successRateValue: Ref<boolean | string | null> = ref(null);
const presetTimeRange = ref("");
const dateRange: Ref<{ from: string; to: string } | null> = ref(null);

watch([successRateProperty], () => {
  if (getInputComponentName.value?.component === QToggle) {
    if (typeof successRateValue.value !== "boolean") {
      successRateValue.value = true;
    }
  } else if (getInputComponentName.value?.component === QInput) {
    if (typeof successRateValue.value !== "string") {
      successRateValue.value = "";
    }
  } else if (getInputComponentName.value?.component === SegmentSelect) {
    successRateValue.value = "";
  }
});

watch([successRateValue, presetTimeRange], () => {
  lookerLinkDisabled.value =
    !getInputComponentName.value ||
    successRateValue.value === null ||
    successRateValue.value === "" ||
    !presetTimeRange.value;
});

const getInputComponentName = computed(() => {
  if (successRateProperty.value === null) {
    return null;
  }
  return successRatePropertyOptions[successRateProperty.value];
});

const { getLookerLink, formatDateRange } = UseConversionReport();

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_expansion_item, {
    class: "text-h6 q-mb-sm",
    label: "Conversion report"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_unref(QSelect), {
                    modelValue: successRateProperty.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((successRateProperty).value = $event)),
                    filled: "",
                    options: Array.from(Object.keys(successRatePropertyOptions)),
                    label: "Success rate property",
                    "data-test": "conversionreport.select.successproperty"
                  }, null, 8, ["modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.productLine !== null)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(getInputComponentName.value?.component), {
                        key: 
                getInputComponentName.value?.segment ||
                getInputComponentName.value?.staticList
              ,
                        modelValue: successRateValue.value,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((successRateValue).value = $event)),
                        "segment-name": getInputComponentName.value?.segment,
                        "static-list": getInputComponentName.value?.staticList,
                        "product-line": _ctx.productLine,
                        label: "Expected value",
                        "data-test": "conversionreport.input.successpropertyvalue"
                      }, null, 8, ["modelValue", "segment-name", "static-list", "product-line"]))
                    : _createCommentVNode("", true)
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-1" }, null, -1)),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_unref(QSelect), {
                    modelValue: presetTimeRange.value,
                    "onUpdate:modelValue": [
                      _cache[5] || (_cache[5] = ($event: any) => ((presetTimeRange).value = $event)),
                      _cache[6] || (_cache[6] = ($event: any) => (dateRange.value = null))
                    ],
                    filled: "",
                    "use-input": "",
                    options: presetDateOptions,
                    label: "Date",
                    "emit-value": "",
                    "map-options": "",
                    "data-test": "conversionreport.select.presetdate"
                  }, {
                    append: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        name: "event",
                        class: "cursor-pointer",
                        onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop","prevent"]))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_popup_proxy, {
                            ref: "qDateProxy",
                            "transition-show": "scale",
                            "transition-hide": "scale"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_date, {
                                modelValue: dateRange.value,
                                "onUpdate:modelValue": [
                                  _cache[2] || (_cache[2] = ($event: any) => ((dateRange).value = $event)),
                                  _cache[3] || (_cache[3] = ($event: any) => (
                        presetTimeRange.value = _unref(formatDateRange)(dateRange.value)
                      ))
                                ],
                                range: "",
                                minimal: "",
                                mask: "YYYY-MM-DD",
                                options: 
                        (d) => {
                          return d < _unref(date).formatDate(Date.now(), 'YYYY/MM/DD');
                        }
                      ,
                                "data-test": "conversionreport.select.daterange"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_5, [
                                    _withDirectives(_createVNode(_component_q_btn, {
                                      label: "Close",
                                      color: "primary",
                                      flat: ""
                                    }, null, 512), [
                                      [_directive_close_popup]
                                    ])
                                  ])
                                ]),
                                _: 1
                              }, 8, ["modelValue", "options"])
                            ]),
                            _: 1
                          }, 512)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ]),
              _createVNode(_component_q_btn, {
                color: "primary",
                size: "sm",
                label: "Go to looker campaign page",
                target: "_blank",
                href: 
            _unref(getLookerLink)(
              _ctx.messageId,
              successRateProperty.value,
              successRateValue.value,
              dateRange.value ? dateRange.value : presetTimeRange.value,
              _ctx.messageType
            )
          ,
                disable: lookerLinkDisabled.value,
                "data-test": "conversionreport.button.looker"
              }, null, 8, ["href", "disable"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})